import { EventBus } from './event_bus';
var xlg = {
	websocket : null,
	wsUri : null,
	tableId : null,
	casinoId : null,
	tryToConnect : true,
	currency:'',

	
	// public
	connect : function(wsUri, casinoId, tableId) {

		var self = this;
		self.currency = casinoId
		self.tryToConnect = true;
		self.wsUri = wsUri;
		console.log('connecting to ' + 'wss://' + wsUri + '/ws' + tableId);
		if(self.websocket !== null && self.websocket.readyState !== 3){
			self.websocket.close();
			console.log('Socket open closing it');
		} 
		self.websocket = new WebSocket('wss://' + wsUri + '/ws');
		self.websocket.onopen = function(evt) {
			self.onWsOpen(evt, casinoId,tableId)
		};
		self.websocket.onclose = function(evt) {
			self.onWsClose(evt)
		};
		self.websocket.onmessage = function(evt) {
			self.onWsMessage(evt)
		};
		self.websocket.onerror = function(evt) {
			self.onWsError(evt)
		};
		if (tableId) {
			self.tableId = tableId;
		}
		self.casinoId = casinoId;
	},
	// public
	onMessage : function(data) {
		// to fill
		EventBus.$emit("tableChanged",data)
		if("tableKey" in data){
			data["tableKey"].forEach(element => {
						this.subscribe("ppcdk00000004246",element,this.currency)
					});
		}

		// if(data["tableKey"]){
		// 	data["tableKey"].forEach(element => {
		// 		this.subscribe("ppcdk00000006697",element,"BIF")
				
		// 	});
		// }else{
		// 	console.log("Daa")
		// }
		
		
	},
	connectionStatus: function(){
		if(self.websocket.readyState == WebSocket.OPEN){
			return true
		}else{
			return false
		}
	},
	// public
	onConnect : function() {
		// to fill
		console.log(self.websocket)
		return true
		// console.log("conected to the server")
	},
	// public
	disconnect : function() {
		var self = this;
		self.tryToConnect = false;
		self.websocket.close();
		console.log('Disconnected');
	},
	// public
	subscribe : function(casinoId, tableId, currency) {
		var subscribeMessage = {
			type : 'subscribe',
			key : tableId,
			casinoId : casinoId,
			currency : currency
		}

		var self = this;
		// console.log('Subscribing ' + tableId);
		var jsonSub = JSON.stringify(subscribeMessage);
		self.doWsSend(jsonSub);
	},
	
	// public
	available : function(casinoId) {
		var availableMessage = {
			type : 'available',
			casinoId : casinoId
		}

		var self = this;
		// console.log('Subscribing ' + tableId);
		var jsonSub = JSON.stringify(availableMessage);
		self.doWsSend(jsonSub);
	},

	onWsOpen : function(evt) {
		console.log("open",evt)
		var self = this;

		// console.log(evt.data);
		if (self.onConnect != null) {
			self.onConnect();
			self.available("ppcdk00000004246")
		}

		console.log('Connected to wss server');
		if (self.tableId) {
			self.subscribe(self.casinoId, self.tableId)
		}
	},

	onWsClose : function(evt) {
		console.log("DISCONNECTED", evt);
		var self = this;
		if (self.tryToConnect === true) {
			console.log("RECONNECTING");
			setInterval(() => {
				self.connect(self.wsUri, self.casinoId, self.tableId);
			}, 10000);
			
		}
	},

	onWsMessage : function(evt) {
		var self = this;
		var data = JSON.parse(evt.data);
		// console.log(evt.data);
		if (self.onMessage != null) {
			self.onMessage(data);
		}
	},

	onWsError : function(evt) {
		console.log('ERROR: ' , evt);
	},

	doWsSend : function(message) {
		var self = this;
		self.websocket.send(message);
	}
};

export default xlg;