import { render, staticRenderFns } from "./carsh_live.casino.vue?vue&type=template&id=28a2044a&scoped=true&"
import script from "./carsh_live.casino.vue?vue&type=script&lang=js&"
export * from "./carsh_live.casino.vue?vue&type=script&lang=js&"
import style0 from "./carsh_live.casino.vue?vue&type=style&index=0&id=28a2044a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a2044a",
  null
  
)

export default component.exports