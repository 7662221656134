<template>
  <div v-frag>    
    <div class="menu">
      <div class="casino-menu" v-if="ids.length == 0">
        <span class="label" @click="setLiveType('POPULAR')"  :class="{ 'text-orange' : type === 'POPULAR' }">{{ $t("casino.popular") }}</span>
        <span class="label" @click="setLiveType('ROULETTE')" :class="{ 'text-orange' : type === 'ROULETTE' }">{{ $t("casino.roulette") }}</span>
        <span class="label" @click="setLiveType('BACCARAT')" :class="{ 'text-orange' : type === 'BACCARAT' }">{{ $t("casino.barracat") }}</span>
        <span class="label" @click="setLiveType('MEGAWHEEL')" :class="{ 'text-orangel' : type === 'MEGAWHEEL' }">{{ $t("casino.mega_wheel") }}</span>
        <span class="label" @click="setLiveType('BLACKJACK')" :class="{ 'text-orange' : type === 'BLACKJACK' }">{{ $t("casino.blackjack") }}</span>
        <span class="label" @click="setLiveType('ONEBJ')" :class="{ 'text-orange' : type === 'ONEBJ' }">{{ $t("casino.one_blackjack") }}</span>
      </div>
    </div> 
  
    <template v-if="Casino">
      <template v-if="ids.length > 0">
        <CashLive :tables="Casino.lobby[Casino.tableType]" :tableType="Casino.tableType" :ids="ids"/>
      </template>
      <template v-else>
        <SubLive :tables="Casino.lobby[Casino.tableType]" :tableType="Casino.tableType"/>
      </template>
      
    </template> 
  </div>
</template>

<script>
import SubLive from "@/components/Pages/casino/sub_live.casino";
import CashLive from "@/components/Pages/casino/carsh_live.casino";
import {mapState, mapActions,mapGetters} from "vuex"
import { CASINO } from "@/config/casino_confs";
import DGA from "@/services/dga";
import { EventBus } from "@/services/event_bus";
import countryToCurrency from "country-to-currency";

export default {
  props:["ids"],
  data() {
    return {
      type:"POPULAR"
    }
  },
  computed:{
    ...mapState(["Casino"]),
    ...mapGetters({
      geo:'geo',
      user: 'user',
    }),
    currency() {
      if(this.user) {
        return (this.user.iso == null) ? 'USD' : countryToCurrency[this.user.iso]
      } else {
        return (this.geo == null) ? 'USD' : countryToCurrency[this.geo?.country_code2]
      }
    },
  },
  components:{
    SubLive,
    CashLive
  },
  mounted(){
    DGA.connect("dga.pragmaticplaylive.net",this.currency.toUpperCase());
    EventBus.$on("tableChanged", this.handleTable);
  },  
  methods: {
    ...mapActions({
      change_live_type: "set_casino_live_type",
    }),
    setLiveType: function (params) {
      this.change_live_type(params);
      this.type=params
    },
    getImageUrl(game) {
      return CASINO.getImageUrl(game.gameID);
    },
    handleTable(data) { 
      this.handlePopular(data);
      let CasinoStore = this.Casino.lobby;
      if (CasinoStore[data.tableType].length > 0) {
        let inArr = CasinoStore[data.tableType].find(
          (i) => i.game.tableId === data.tableId
        );
        if (!inArr) {
          //dispatch action here to mutate store table
          let payload = CasinoStore[data.tableType].push({ game: data });
          this.$store.dispatch("set_lobby_tables",{table:data.tableType,paylaod:payload})
        } else {
          //  this[data.tableType][index] = {game:data}
          CasinoStore[data.tableType] = CasinoStore[data.tableType].map(
            (item) => {
              if (item.game.tableId === data.tableId) return { game: data };
              else return item;
            }
          );
          this.$store.dispatch("set_lobby_tables",{table:data.tableType,paylaod:CasinoStore[data.tableType] })
        }
      } else {
        CasinoStore[data.tableType].push({ game: data });
      }
    },
    handlePopular(tableObj) {
      let CasinoStore = this.Casino.lobby;
      if (CasinoStore.POPULAR.length > 0) {
          let inPopular = CasinoStore.POPULAR.find(
            (i) => i.game.tableId === tableObj.tableId
          );
          if (!inPopular) {
            CasinoStore.POPULAR.push({ game: tableObj });
          } else {
            CasinoStore.POPULAR = CasinoStore.POPULAR.map((item) => {
              if (item.game.tableId === tableObj.tableId)
                return { game: tableObj };
              else return item;
            });
          }
      
      } else {
        CasinoStore.POPULAR.push({ game: tableObj });
      }
    },
  },
}
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .hoverable {
    overflow-x: scroll;
    white-space: nowrap;
    margin-bottom: 0px;
      /* display: inline; */
  }
  .featured-header.links {
      border-top: 0px;
  }
  .featured-header {
      background-color: transparent;
      padding: 8px 0px;
  }
}
.hoverable li {
  cursor: pointer;
  /* font-weight: bold; */
}
.hoverable{
  cursor: pointer;
  padding: 5px 0px;
}
.cl {
  color:rgb(247, 148, 30);
}
.menu {
  padding: 5px 10px;
}
.menu span {
  display: inline-block;
  padding: 5px 10px;
  background-color: var(--bg-dark-medium);
  border-radius: 15px;
  font-weight: 400;
  font-size: 11px;
  text-transform: capitalize;
}
</style>