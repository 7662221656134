<template>
    <div v-frag>
        <div class="casino-body sub-live">
            <div v-frag v-for="(table,index) in tables" :key="index">
                <div @click="launchGame(table.game)" class="live-card" v-if="ids.includes(table.game.tableId) " >
                    <div class="live-card-body">
                        <img 
                        :src="table.game.tableImage" alt="Card image cap" class="casino-img">
                        <span class="cost pos-left">{{currency.toUpperCase()}} {{ table.game.tableLimits.minBet}} - {{  currency.toUpperCase() }} {{ table.game.tableLimits.maxBet}}</span>
                        <span class="cost pos-right">
                            <i aria-hidden="true" class="fa fa-user"></i> +{{ table.game.totalSeatedPlayers}}
                        </span>
                        <div class="buttons" v-if="!!table.game.last20Results">
                            <span class="btn-span" :style="{background: result.color}" v-for="(result,key) in table.game.last20Results" :key="key">{{ result.result }}</span>
                        </div>
                    </div>
                    <div class="live-info">
                        <div class="live-info-name">
                            <span class="">
                                {{ table.game.tableType}}                        
                            </span>
                            <span>{{ table.game.tableName}}</span>
                        </div>
                        <div v-if="table.game.tableType === 'BLACKJACK'" class="live-info-name">
                            <ul class="list-unstyled ul">
                                <li>Available Seats: <span class="ml-1 badge" variant="secondary">{{table.game.availableSeats}}</span> </li>
                                <li>MultiseatLimit <span class="ml-1 badge" variant="secondary">{{table.game.multiseatLimit}}</span> </li>
                            </ul>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {mapState,mapGetters} from "vuex"
    import { CASINO} from "@/config/casino_confs";
    import countryToCurrency from "country-to-currency";
    export default {
        name: "sub_live_casino",
        props:["tables","tableType","ids"],
        data(){
            return{
            isLoading: false,
            fullPage: true
            }
        },
        mounted(){
            this.isLoading = false
        },
        computed:{
            ...mapState(["Casino"]),
            filteredTables(){
               return this.games
            },
            ...mapGetters({
                geo:'geo',
                user: 'user',
            }),
            currency() {
                if(this.user) {
                    return (this.user.iso == null) ? 'USD' : countryToCurrency[this.user.iso]
                } else {
                    return (this.geo == null) ? 'USD' : countryToCurrency[this.geo?.country_code2]
                }
            },
        },
        methods:{       
           games(){
                if(this.tableType === 'POPULAR'){
                    this.tables.shift()
                    return this.tables
                }else{
                    return this.tables
                }
           },
            getImageUrl(game){
               CASINO.getImageUrl(game.tableId);
            },
            launchGame(table){
                this.$router.push({name:"live_renderer", params:{gameId: table.tableId}})
               
            },
            
        }
    }
</script>

<style scoped>
   
    .live-card {
        border: 1px solid #242426;
        /* border-radius: 10px; */
        position: relative;
        margin-bottom:10px;
    }
    .live-card .live-card-body {
        position: relative;
        width: 100%;
    }
    .casino-img {
        width: 100%;
        /* border-top-left-radius: 10px;
        border-top-right-radius: 10px;      */
        max-height: 180px;
    }
    .cost {
        position: absolute;
        top: 5px;
        font-size: 12px;
        display: block;
        padding: 5px;
        background-color: var(--bg-orange);
        /* font-weight: bold; */
        /* border-radius:5px; */
        color: #ffffff;
    }
    
    .pos-left {
        left: 10px;
    }
    .pos-right {
        right: 10px;
    }
    .live-info {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        /* font-weight: bold; */
    }
    .live-info .live-info-name {
        display: flex;
        flex-direction: column;
    }

    .live-info .live-info-name .ul {
        margin-bottom: 0px;
    }
    .buttons {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-top: 5px;
        background-color: rgba(255, 255, 255, .18);
    }
    .hoverable{
    cursor: pointer;

}
    .btn-span {
        width: 25px;
        font-size: 11px;
        height: 25px;
        font-weight: 700;
        border-radius: 10%;
        display: inline-flex;
        align-items: center;
        color: #fff;
        justify-content: center;
        align-items: center;
        background-color: teal;
        margin-left: 4px;
        margin-bottom: 4px;
    }
    .ml-1 {
        margin-left: 5px;
    }
</style>
